<template>
  <div id="stays">
    <Loading v-if="!loaded" />
    
    <div class="no-stays" v-if="stays.length < 1 && loaded">
      <img :src="require('@/assets/Lock.png')">
      <p>{{$t('lock.stays')}}</p>
    </div>

    <div v-for="stay in stays" :key="stay.id" class="stay" @click="$router.push(`/hotel/${stay.propertyId || stay.id}`)">
      <div class="title">{{stay.propertyName || '#' + stay.bookingNo + (stay.status == 'PENDING_TO_APPROVE' ? ' ' + $t('order.statusTypes.pending') : '')}}</div>
      <div class="content">
        <div class="avatar" :style="{backgroundImage: `url(${stay.avatar})`}" />
        <div class="info">
          <div class="date">
            <DateTimeBox :date="new Date(stay.checkInTime)" />
            <div class="seperator"></div>
            <DateTimeBox :date="new Date(stay.checkOutTime)" />
          </div>
          <DiscloseableDescription :description="stay.propertyDescription || stay.id" :limit="45" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiscloseableDescription from '@/components/DiscloseableDescription'
import DateTimeBox from '@/components/DateTimeBox'

export default {
  components: {DiscloseableDescription, DateTimeBox},
  data() {
    return {
      stays: [],
      loaded: false
    }
  },
  mounted() {
    this.$api.get(`/pms/user-stays/`)
    .then(res => {
      this.stays = res.data.data
      this.loaded = true
    })
  },
}
</script>

<style lang="scss">
#stays {
  margin: 0 auto;
  max-width: 500px;
  display: flex;
  flex-direction: column;

  .no-stays {
    color: #7a7a7a;
    font-size: 16px;
    text-align: center;

    img {
      opacity: .5;
      width: 100px;
      margin-bottom: 10px;
    }
  }

  .stay {
    border-bottom: #ccc solid 1px;
    padding: 10px;
    background-color: transparent;
    transition: .2s all;
    cursor: pointer;

    &:hover {
      background-color: rgba(0, 0, 0, .02);
    }

    >.title {
      font-weight: 500;
      font-size: 15px;
      color: #49494b;
      margin-bottom: 5px;
    }

    >.content {
      display: flex;

      >.avatar {
        margin-right: 10px;
        width: 110px;
        min-width: 110px;
        height: 110px;
        min-width: 110px;
        min-height: 110px;
        background-color: #969FAA;
        background-position: center;
        background-size: cover;
      }

      >.info {
        >.date {
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          > .seperator {
            margin: 0 15px;
            width: 25px;
            height: 3px;
            background-color: rgb(124, 124, 124);
          }
        }
      }
    }
  }
}
</style>