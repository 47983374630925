<template>
  <div class="discloseable-description">
    <div
      class="description"
      :class="{ disclosed, discloseable }"
      :style="{ maxHeight: limit + 'px' }"
      @click="click"
    >
      <div v-html="description" v-html-reactive-links />
      <div class="overlay" v-if="discloseable">
        <md-icon v-if="disclosed">{{ 'expand_less' }}</md-icon>
      </div>
    </div>

    <div class="ghost" ref="ghost">
      <div class="description disclosed">
        <div v-html="description" v-html-reactive-links />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['description', 'limit'],
  data() {
    return {
      discloseable: false,
      disclosed: false,
    }
  },
  methods: {
    updateDiscloseable() {
      this.discloseable = this.$refs.ghost.clientHeight > this.limit
      this.disclosed = false
    },
    click() {
      if (this.discloseable) this.disclosed = !this.disclosed
    },
  },
  mounted() {
    let interval = setInterval(() => {
      if (!this.$refs.ghost) return
      if (this.$refs.ghost.clientHeight > 0) {
        this.updateDiscloseable()
        clearInterval(interval)
      }
    }, 100)
  },
  watch: {
    description: {
      immediate: true,
      handler() {
        this.$nextTick(this.updateDiscloseable)
      },
    },
  },
}
</script>

<style lang="scss">
.discloseable-description {
  .description {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.1;
    max-height: 75px;
    overflow: hidden;
    position: relative;
    color: #6a6a6a;

    &.disclosed {
      max-height: initial !important;
      overflow: initial;

      > .overlay {
        position: initial;
        background: transparent;
        height: initial;
      }
    }

    &.discloseable {
      cursor: pointer;
    }

    > .overlay {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      cursor: pointer;
      height: 30px;
      background: linear-gradient(rgba(255, 255, 255, 0), white);

      > * {
        z-index: 1;
      }

      > .md-icon {
        color: black;
      }
    }
  }

  > .ghost {
    position: fixed;
    visibility: hidden;
  }
}
</style>
