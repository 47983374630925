<template>
  <div class="date-time-box">
    <div class="day">{{ date.getDate() }}</div>
    <div class="info">
      <div class="weekDay">
        {{ date.toLocaleString($t('datetimeFormat'), { weekday: 'short' }) }}
      </div>
      <div class="month">
        {{ date.toLocaleString($t('datetimeFormat'), { month: 'short' }) }}
      </div>
      <div class="time">
        {{
          date
            .toLocaleTimeString()
            .split(':')
            .slice(0, 2)
            .join(':')
        }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['date'],
}
</script>

<style lang="scss">
.date-time-box {
  color: #7a7a7a;

  display: flex;
  justify-content: center;
  align-items: center;

  .day {
    font-size: 24px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 5px;
  }
}
</style>
